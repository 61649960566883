import {
  IWixAPI,
  InitAppForPageFn,
  ViewerScriptFlowAPI,
} from '@wix/yoshi-flow-editor';
import { getPostFeedPage } from '@wix/ambassador-blog-frontend-adapter-public-v2-post-feed-page/http';
import type { GetPostFeedPageResponse } from '@wix/ambassador-blog-frontend-adapter-public-v2-post-feed-page/types';
import { getPostPage } from '@wix/ambassador-blog-frontend-adapter-public-v2-post-page/http';
import type { GetPostPageResponse } from '@wix/ambassador-blog-frontend-adapter-public-v2-post-page/types';
import { listCategories } from '@wix/ambassador-blog-v3-category/http';
import { BLOG_POST_PAGE_SECTION_ID } from '@wix/communities-universal/dist/src/constants/appsConfig';
import { getSectionPathname } from '../external/common/controller/helpers';
import { Router } from '../external/common/router';
import { normalizePostV3 } from '../external/common/services/post-utils';
import { resolvePostSlug } from '../external/common/services/slug';
import {
  ROUTE_404,
  ROUTE_POST,
  ROUTE_POST_ADVANCED_SLUG,
} from '../external/post-page/constants/routes';
import { customRouteHandler } from '../external/post-page/controller/route-handlers/custom-route-handler';
import { getSectionUrl } from '../external/post-page/services/get-section-url';
import { isEditorOrPreview } from './selectors';

const getPostPageData = (
  response: GetPostFeedPageResponse | GetPostPageResponse,
) => {
  if ('postPage' in response) {
    return response.postPage;
  } else if ('postFeedPage' in response) {
    return {
      ...response.postFeedPage,
      post: response.postFeedPage?.posts?.posts?.[0],
    };
  }
};

interface GetPostAndCategoriesParams {
  wixCodeApi: IWixAPI;
  initParams: Parameters<InitAppForPageFn>[0];
  flowAPI: ViewerScriptFlowAPI;
}

export const getPostAndCategories = async ({
  wixCodeApi,
  initParams,
  flowAPI,
}: GetPostAndCategoriesParams) => {
  const editorOrPreview = isEditorOrPreview(wixCodeApi);

  const [
    postResponse,
    {
      data: { categories },
    },
  ] = await Promise.all([
    editorOrPreview
      ? getEditorPostRequest({
          flowAPI,
          languageCode: wixCodeApi.site.language ?? 'en',
        })
      : getViewerPostRequest({ wixCodeApi, initParams, flowAPI }),
    flowAPI.httpClient.request(listCategories({})),
  ]);

  if (!postResponse.data) {
    throw new Error('No post');
  }

  const postPageData = getPostPageData(postResponse.data);

  if (!postPageData) {
    throw new Error('No post page data available');
  }

  const { post, tags, appData } = postPageData;

  if (!post) {
    throw new Error('No post data available');
  }

  return {
    post: {
      ...normalizePostV3(post),
      tags: tags ?? [],
    },
    categories,
    appData,
  };
};

interface GetViewerPostRequestParams {
  wixCodeApi: IWixAPI;
  initParams: Parameters<InitAppForPageFn>[0];
  flowAPI: ViewerScriptFlowAPI;
}

const getViewerPostRequest = async ({
  wixCodeApi,
  initParams,
  flowAPI,
}: GetViewerPostRequestParams) => {
  const router = new Router();
  const sectionUrl = await getSectionUrl({
    wixCodeApi,
    instanceId: initParams.instanceId,
    sectionId: BLOG_POST_PAGE_SECTION_ID,
  });
  const pathname = getSectionPathname({ wixCodeApi, sectionUrl });

  router.add(ROUTE_POST);
  router.add(ROUTE_POST_ADVANCED_SLUG);
  router.addCustomRouteHandler(() => customRouteHandler(wixCodeApi));
  router.fallback(ROUTE_404);

  const matchPathResult = await router.matchPath({ pathname });
  const slug = resolvePostSlug(matchPathResult.params) as string;

  return flowAPI.httpClient.request(
    getPostPage({
      postId: decodeURI(slug),
      languageCode: wixCodeApi.site.language ?? 'en',
      translationsName: 'main',
      loadRichContent: true,
    }),
  );
};

interface GetEditorPostRequestParams {
  flowAPI: ViewerScriptFlowAPI;
  languageCode: string;
}

const getEditorPostRequest = ({
  flowAPI,
  languageCode,
}: GetEditorPostRequestParams) => {
  return flowAPI.httpClient.request(
    getPostFeedPage({
      languageCode,
      page: 1,
      pageSize: 1,
      includeContent: true,
      loadRichContent: true,
    }),
  );
};
