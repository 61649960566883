import { postPageOpened } from '@wix/bi-logger-blog-app-uou/v2';
import {
  categoryIds,
  isPublished,
  paidPlanIds,
  paidPlansHasAccess,
} from '../../external/common/bi-events/event-helpers';
import { NormalizedPost } from '../../external/common/types';
import { AppData } from '../../viewer.app';
import blocksModel from './model';

export default blocksModel.createController(
  ({ appData, flowAPI, controllerConfig }) => ({
    pageReady: async () => {
      const { post, readingSessionId, seoTags } = await (
        appData as AppData
      ).fetchPostPageRenderModel();

      flowAPI.bi?.report(
        postPageOpened({
          reading_session_id: readingSessionId,
          // @ts-expect-error
          referrer: flowAPI.controllerConfig.wixCodeApi.window.referrer,
          post_stable_id: post.id,
          paid_plan_has_access: paidPlansHasAccess(post) || undefined,
          paid_plan_id: paidPlanIds(post),
          category_ids: categoryIds(post),
          is_published: Boolean(isPublished(post)),
        }),
      );

      controllerConfig.wixCodeApi.seo.renderSEOTags(seoTags);
    },
    exports: {
      ...blocksModel.getExports(),
      getPost: async (): Promise<NormalizedPost> => {
        const { post } = await (appData as AppData).fetchPostPageRenderModel();
        return post;
      },
    },
  }),
);
