import type { I$W, PlatformControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { likeClick } from '@wix/bi-logger-blog-app-uou/v2';
import { BlocksWidgetFacade } from '../../external/blocks-widget/blocks-widget.facade';
import type { PostPageRenderModel } from '../../external/blocks-widget/post-page-render-model';
import { isPublished } from '../../external/common/bi-events/event-helpers';
import { NormalizedPost } from '../../external/common/types';
import type { AppData, LikeStatusChangeEvent } from '../../viewer.app';

export type PostCounters = {
  id: string;
  likeCount: number;
  viewCount: number;
  commentsCount: number;
  isLiked: boolean;
  post: NormalizedPost | undefined;
};

export class CounterController {
  private state: PostCounters = {
    id: '',
    isLiked: false,
    likeCount: 0,
    viewCount: 0,
    commentsCount: 0,
    post: undefined,
  };

  private readonly ui = {
    likeStatesContainer: this.$w('#statesContainer') as $w.MultiStateBox,
    likeCountDefault: this.$w('#likesDefaultNumber') as $w.Text,
    likeButtonEmpty: this.$w('#likesDefaultBlack') as $w.Text,
    likeCountFilled: this.$w('#likesFilledNumber') as $w.Text,
    likeButtonFilled: this.$w('#likesFilledBlack') as $w.Text,
    viewsCount: this.$w('#viewsNumber') as $w.Text,
    commentsCount: this.$w('#commentsNumber') as $w.Text,
  };

  private readonly facade = new BlocksWidgetFacade(this.flowAPI);

  constructor(
    private $w: I$W,
    private flowAPI: PlatformControllerFlowAPI,
    private appData: AppData,
  ) {
    appData?.subjects.likeStatusChanged.subscribe((event) => {
      this.handleLikeChangeEvent(event.data);
    });
  }

  initialize = (model: PostPageRenderModel) => {
    if (!model.post.id) {
      throw new Error('No post id provided');
    }

    this.setState({
      id: model.post.id,
      likeCount: model.metadata.likeCount || 0,
      viewCount: model.metadata.viewCount || 0,
      commentsCount: model.metadata.totalComments || 0,
      isLiked: model.metadata.isLiked || false,
      post: model.post,
    });

    this.ui.likeButtonEmpty.onClick(this.handleLikeClick);
    this.ui.likeButtonFilled.onClick(this.handleLikeClick);
  };

  private async setState(nextState: CounterController['state']) {
    this.state = nextState;
    await this.applyStateToUi();
  }

  private handleLikeClick = async () => {
    if (!this.state.id) {
      throw new Error('No id provided');
    }

    const nextLikeState = !this.state.isLiked;
    const nextLikeCount = this.state.likeCount + (nextLikeState ? 1 : -1);

    this.flowAPI.bi?.report(
      likeClick({
        flag: nextLikeState,
        likes_count: nextLikeCount,
        is_published: Boolean(isPublished(this.state.post)),
        post_stable_id: this.state.id,
      }),
    );

    if (nextLikeState) {
      await this.facade.likePost(this.state.id);
    } else {
      await this.facade.dislikePost(this.state.id);
    }

    this.setState({
      ...this.state,
      isLiked: nextLikeState,
      likeCount: nextLikeCount,
    });

    this.appData?.subjects.likeStatusChanged.next({
      isLiked: nextLikeState,
      likeCount: nextLikeCount,
    });
  };

  private async applyStateToUi() {
    if (this.state.isLiked) {
      await this.ui.likeStatesContainer.changeState('filled');
      this.ui.likeCountFilled.text = String(this.state.likeCount);
    } else {
      await this.ui.likeStatesContainer.changeState('default');
      this.ui.likeCountDefault.text = String(this.state.likeCount);
    }

    this.ui.viewsCount.text = String(this.state.viewCount);
    this.ui.commentsCount.text = String(this.state.commentsCount);
  }

  private handleLikeChangeEvent(payload: LikeStatusChangeEvent) {
    this.setState({
      ...this.state,
      likeCount: payload.likeCount,
      isLiked: payload.isLiked,
    });
  }
}
